var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CRow',{staticClass:"mt-3"},[_c('CCol',{attrs:{"col":"12"}},[_c('CCard',{staticClass:"zq-card-table"},[_c('CCardBody',[_c('CDataTable',{ref:"dataTableRef",staticClass:"zq--table",attrs:{"id":"dataTableId","items":_vm.rewardData,"fields":_vm.tableFields,"responsive":true,"border":false},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.name))])]}},{key:"rank",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.rewardRank))])]}},{key:"value",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.rewardValue))])]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"fixed-column-end",class:{'zq--active-fixed-column': _vm.activeToggleId === item.id},attrs:{"data-id":item.id}},[_c('div',{staticClass:"zq--table-actions-wrap"},[_c('ul',{staticClass:"m-0 p-0"},[_c('CDropdown',{staticClass:"c-header-nav-items custom-dropbox",attrs:{"inNav":"","placement":"left","add-menu-classes":"p-0"},scopedSlots:_vm._u([{key:"toggler",fn:function(){return [_c('CButton',{staticClass:"zq--table-actions--single",on:{"click":function($event){return _vm.handleToggle(item.id)}}},[_c('ClIcon',{attrs:{"cilIcon":"cil-options","iconTypeProp":"cil","imgIcon":"actions-more"}})],1)]},proxy:true}],null,true)},[_c('CDropdownItem',{staticClass:"p-0"},[_c('CButton',{staticClass:"action-custom-button",attrs:{"pill":"","color":"dark","variant":"ghost"},on:{"click":function($event){_vm.deleteModal = true}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.delete'))+" ")])],1)],1)],1)])])]}}])})],1)],1)],1)],1),_c('Modal',{attrs:{"modalShow":_vm.deleteModal,"messageGeneral":_vm.messageGeneral,"title":_vm.delRewardsTitle},on:{"doFunction":_vm.deleteReward,"toggle-modal":_vm.closeModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
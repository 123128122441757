<template>
  <div>
    <CRow class="mt-3">
      <CCol col="12">
        <CCard class="zq-card-table">
          <CCardBody>
            <CDataTable
                id="dataTableId"
                ref="dataTableRef"
                class="zq--table"
                :items="rewardData"
                :fields="tableFields"
                :responsive="true"
                :border="false"
            >
              <template #name="{item}">
                <td>{{ item.name }}</td>
              </template>
              <template #rank="{item}">
                <td>{{ item.rewardRank }}</td>
              </template>
              <template #value="{item}">
                <td>{{ item.rewardValue }}</td>
              </template>
              <template #actions="{ item }">
                <td
                    :data-id="item.id"
                    :class="{'zq--active-fixed-column': activeToggleId === item.id}"
                    class="fixed-column-end"
                >
                  <div class="zq--table-actions-wrap">
                    <ul class="m-0 p-0">
                      <CDropdown
                          inNav
                          class="c-header-nav-items custom-dropbox"
                          placement="left"
                          add-menu-classes="p-0"
                      >
                        <template #toggler>
                          <CButton class="zq--table-actions--single" @click="handleToggle(item.id)">
                            <ClIcon cilIcon="cil-options" iconTypeProp="cil" imgIcon="actions-more" />
                          </CButton>
                        </template>
                        <CDropdownItem class="p-0">
                          <CButton
                              pill
                              color="dark"
                              class="action-custom-button"
                              variant="ghost"
                              @click="deleteModal = true"
                          >
                            {{ $t('buttons.delete') }}
                          </CButton>
                        </CDropdownItem>
                      </CDropdown>
                    </ul>
                  </div>
                </td>
              </template>
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <Modal
        :modalShow="deleteModal"
        :messageGeneral="messageGeneral"
        :title="delRewardsTitle"
        @doFunction="deleteReward"
        v-on:toggle-modal="closeModal"
    />
  </div>
</template>

<script>
import ClIcon from '@/shared/UI/ClIcon.vue';
import Modal from '@/shared/UI/Modal.vue';

export default {
  name: 'PreviewRewardTable',
  components: { Modal, ClIcon },
  props: {
    rewardData: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      tableFields: ['name', 'rank', 'value', 'actions'],
      activeToggleId: null,
      deleteModal: false,
      messageGeneral: 'You are about to delete a reward, Are you sure?',
      delRewardsTitle: 'Delete reward',
    };
  },
  methods: {
    handleToggle(id) {
      this.activeToggleId = id;
    },
    closeModal() {
      this.deleteModal = false;
    },
    deleteReward() {
      this.deleteModal = false;
      this.tableData = []
      this.$emit('deleteReward');
    },
  }
};
</script>

<style scoped lang="scss">

</style>